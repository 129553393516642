<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <template #search>
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="">
          <a-select allowClear v-model:value="searchForm.couponType" :options="$store.state.enumAll.couponTypeEnum"
            placeholder="优惠券类型" style="width:140px" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.name" placeholder="优惠券名称" />
        </a-form-item>
        <a-space>
          <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch" :disabled="loading">
            查询
          </a-button>
          <a-button @click="reset">
            重置
          </a-button>
        </a-space>
      </a-form>
    </template>
    <template #toolbarLeft>
      <a-space>
        <a-button @click="couponShow = true, editType = 0, addForm = { couponType: {} }">
          新建优惠券
        </a-button>
        <a-button @click="couponMarketShow = true, addCouponMarket = { }">
          市场优惠券
        </a-button>
      </a-space>
    </template>
    <a-table :scroll="{ x: 1200, y: 700 }" size="small" :columns="columns" :row-key="record => record.id"
      :data-source="listData" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
      <!-- <template #time="{ record }">
        <span>{{ record.startTimeString+'~'+record.endTimeString }}</span>
      </template> -->
      <template #operation="{ record }">
        <a-space>
          <a @click="onEditShow(record)">编辑</a>
          <a-popconfirm title="确认删除?" ok-text="确认" cancel-text="取消" @confirm="onDeleteData(record.id)">
            <a>删除</a>
          </a-popconfirm>
        </a-space>
      </template>
      <template #days="{ record }">
        {{ record.days }} 天内
      </template>
    </a-table>
    <a-modal destroyOnClose v-if="couponShow" v-model:visible="couponShow" width="500px"
      :title="editType === 0 ? '新建优惠券' : '修改优惠券'" @ok="onCustomerOk">
      <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
        <a-form-item label="优惠券类型" :name="['couponType', 'value']">
          <a-select v-model:value="addForm.couponType.value" :options="$store.state.enumAll.couponTypeEnum"
            placeholder="请选择优惠券类型" @change="addCouponType" />
        </a-form-item>
        <a-form-item label="优惠券名称" name="name">
          <a-input v-model:value="addForm.name" placeholder="优惠券名称" />
        </a-form-item>
        <a-form-item label="优惠券金额/张" name="amt">
          <a-input v-model:value="addForm.amt" placeholder="优惠券金额" suffix="元" />
        </a-form-item>
        <a-form-item label="张数" name="nums">
          <a-input v-model:value="addForm.nums" placeholder="张数" suffix="张" />
        </a-form-item>
        <a-form-item label="使用期限" name="days">
          <a-input v-model:value="addForm.days" placeholder="使用期限" suffix="天内" />
        </a-form-item>
        <a-form-item label="使用规则">
          <a-textarea v-model:value="addForm.remark" placeholder="备注" />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal destroyOnClose v-if="couponMarketShow" v-model:visible="couponMarketShow" width="500px"
             title="生成市场优惠券用于发放" @ok="onCouponMarketOk">
      <a-form layout="vertical" ref="formRef" :model="addCouponMarket" :rules="rules">
        <a-form-item label="优惠券名称" name="name">
          <a-input v-model:value="addCouponMarket.name" placeholder="优惠券名称" />
        </a-form-item>
        <a-form-item label="发放方(市场方)" name="issuingParty">
          <a-input v-model:value="addCouponMarket.issuingParty" placeholder="发放方(市场方)：宏盟、锦和" />
        </a-form-item>
        <a-form-item label="优惠券编号前缀" name="prefix">
          <a-input v-model:value="addCouponMarket.prefix" placeholder="发放方(市场方)：HM，JH" />
        </a-form-item>
        <a-form-item label="优惠券金额/张" name="amt">
          <a-input v-model:value="addCouponMarket.amt" placeholder="优惠券金额" suffix="元" />
        </a-form-item>
        <a-form-item label="总发放张数" name="nums">
          <a-input v-model:value="addCouponMarket.nums" placeholder="总发放张数" suffix="张" />
        </a-form-item>
        <a-form-item label="使用期限" name="days">
          <a-input v-model:value="addCouponMarket.days" placeholder="使用期限" suffix="天内" />
        </a-form-item>
        <a-form-item label="使用规则">
          <a-textarea v-model:value="addCouponMarket.remark" placeholder="备注" />
        </a-form-item>
      </a-form>
    </a-modal>
  </HxTable>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { page, add, deleteId, edit, coupunTypeList, batchGenerateExchange } from '@/api/BEnd/coupon'
import { useStore } from 'vuex'
import HxTable from '@/components/HxTable'
import { message } from 'ant-design-vue'

export default {
  components: {
    HxTable
  },
  setup () {
    const store = useStore()
    const formRef = ref()
    const state = reactive({
      loading: false,
      editType: 0,
      couponShow: false,
      couponMarketShow: false,
      listData: [],
      searchForm: {},
      addForm: { couponType: {} },
      addCouponMarket: {},
      columns: [
        {
          title: '优惠券名称',
          align: 'center',
          dataIndex: 'name'
        },
        {
          title: '市场方',
          align: 'center',
          dataIndex: 'issuingParty'
        },
        {
          title: '优惠券前缀',
          align: 'center',
          dataIndex: 'prefix'
        },
        {
          title: '优惠券类型',
          dataIndex: 'couponType.label',
          align: 'center'
        },
        // {
        //   title: '起止时间',
        //   align: 'center',
        //   slots: {
        //     customRender: 'time'
        //   }
        // },
        {
          title: '面额',
          dataIndex: 'amt',
          align: 'center'
        },
        {
          title: '张数',
          dataIndex: 'nums',
          align: 'center'
        },
        {
          title: '使用期限',
          dataIndex: 'days',
          align: 'center',
          slots: {
            customRender: 'days'
          }
        },
        {
          title: '使用规则',
          dataIndex: 'remark',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '操作',
          fixed: 'right',
          align: 'center',
          width: '8%',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      rules: {
        couponType: { value: [{ required: true, message: '请选择优惠券类型', trigger: 'change', type: 'number' }] },
        name: { required: true, message: '请填写优惠券名称', trigger: 'change' },
        issuingParty: { required: true, message: '请填写发放方', trigger: 'change' },
        prefix: { required: true, message: '请填写优惠券编号', trigger: 'change' },
        amt: { required: true, message: '请填写优惠券金额', trigger: 'change' },
        nums: { required: true, message: '请填写发放张数', trigger: 'change' },
        days: { required: true, message: '请填写使用期限', trigger: 'change' }
      }
    })
    // 搜索选择优惠券类型
    const setCouponType = (e, v) => {
      state.searchForm.couponType = v
    }
    // 添加选择优惠券类型
    const addCouponType = (e, v) => {
      state.addForm.couponType = v
    }
    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
      })
        .finally(() => {
          state.loading = false
        })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {}
      loadData()
    }
    // 优惠券确认点击按钮
    const onCustomerOk = () => {
      formRef.value
        .validate()
        .then(() => {
          if (state.editType === 0) {
            add({ ...state.addForm }).then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                state.couponShow = false
                loadData()
              }
            })
          } else {
            edit({ ...state.addForm }).then(res => {
              if (res.code === 10000) {
                message.success(res.msg)
                state.couponShow = false
                loadData()
              }
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    } // 优惠券确认点击按钮
    const onCouponMarketOk = () => {
      formRef.value
        .validate()
        .then(() => {
          batchGenerateExchange({ ...state.addCouponMarket }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.couponMarketShow = false
              loadData()
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    }
    // 优惠券确认删除
    const onDeleteData = (id) => {
      deleteId(id).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          loadData()
        }
      })
    }
    // 优惠券修改开启面板
    const onEditShow = (r) => {
      state.editType = 1
      state.addForm = r
      state.couponShow = true
    }
    onMounted(() => {
      loadData()
      if (store.state.enumAll.couponTypeEnum.length <= 0) {
        coupunTypeList().then(res => {
          if (res.code === 10000) {
            store.state.enumAll.couponTypeEnum = res.data
          }
        })
      }
    })
    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      setCouponType,
      handleTableChange,
      addCouponType,
      formRef,
      onCustomerOk,
      onCouponMarketOk,
      onDeleteData,
      onEditShow
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
